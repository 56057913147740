import React, { useEffect, useRef } from "react"
import imgAttachment from "../asset/FORM PEMESANAN-04.png"
import imgBA from "../asset/FORM PEMESANAN-02.png"
import imgLogo from "../asset/FORM PEMESANAN-03.png"
import { useState } from "react"
import axios from "axios"
import { useLocation, useNavigate } from "react-router"
import { baseurl } from "../config/http"
import Swal from "sweetalert2"
import { Clipboard2, Copy } from "react-bootstrap-icons"
export default function PrivateSale() {
  const navigate = useNavigate()
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const updateDimensions = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [])
  const rasioLayar = 1370
  const { pathname } = useLocation()
  useEffect(() => {
    setReff(pathname.split("/")[2])
  }, [pathname])

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [address, setAddress] = useState("")
  const [total, setTotal] = useState("")
  const [nominal, setNominal] = useState(0) //button
  const [evidence, setEvidence] = useState("")
  const [reff, setReff] = useState("")

  useEffect(() => {
    if (reff) {
      axios
        .get(baseurl + "/refferal")
        .then(({ data }) => {
          console.log()
          let find = data.data.find((e) => e.refferal === reff)
          if (!find) {
            window.location.replace("https://konkoin.id")
          }
        })
        .catch((err) => console.log(err))
    }
  }, [reff])

  useEffect(() => {
    if (total <= 10000000) {
      setNominal(1)
    } else if (total <= 100000000) {
      setNominal(2)
    } else if (total <= 500000000) {
      setNominal(3)
    } else if (total > 500000000) {
      setNominal(4)
    }
  }, [total])
  const [validateButton, setValidateButton] = useState(false)
  useEffect(() => {
    if (
      firstName &&
      lastName &&
      phone &&
      address &&
      total &&
      nominal &&
      evidence &&
      reff
    ) {
      setValidateButton(true)
    } else {
      setValidateButton(false)
    }
  }, [firstName, lastName, phone, address, total, nominal, evidence, reff])

  const inputFile = useRef(null)
  const onButtonClick = () => {
    inputFile.current.click()
  }

  const onFileChangeCapture = (e) => {
    console.log(e)
    const file = e.target.files[0]
    const param = e.target.name
    const formData = new FormData()
    formData.append("image", file)
    formData.append("title", param)

    axios
      .post(baseurl + "/upload-private-sale", formData)
      .then((resp) => {
        setEvidence(resp.data.link)
      })
      .catch((err) => console.log(err))
  }

  const handleSubmit = () => {
    if ((firstName, lastName, phone, address, total, nominal, evidence, reff)) {
      axios
        .post(baseurl + "/private-sale", {
          firstname: firstName,
          lastname: lastName,
          phoneNumber: phone.toString(),
          address,
          total,
          button: nominal,
          evidence,
          reff,
        })
        .then((resp) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "update success",
            showConfirmButton: false,
            timer: 1500,
          })
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
        .catch((err) =>
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "update error",
            showConfirmButton: false,
            timer: 1500,
          })
        )
    }
  }

  console.log(evidence)
  return (
    <>
      <div
        className="container-fluid p-0 m-0 scrollbar-none"
        style={{
          height: "100vh",
          backgroundColor: "#FFFFFF",
          overflow: width < rasioLayar ? "scroll" : "none",
        }}
      >
        <div className={width < rasioLayar ? "h-100" : "d-flex h-100"}>
          {width < rasioLayar ? (
            <div
              className="d-flex"
              style={{ backgroundColor: "#d91f2d", paddingTop: 25 }}
            >
              <div className="w-100">
                <img
                  src={imgBA}
                  className="img-fluid"
                  alt=""
                  style={{ position: "", width: "100%", left: "-8%" }}
                />
              </div>

              <div className="pe-4 mt-2">
                <div className="d-flex justify-content-end">
                  <img
                    src={imgLogo}
                    className="img-fluid"
                    alt=""
                    style={{ width: "65%", marginBottom: 20 }}
                  />
                </div>
                <div
                  style={{
                    fontSize:width < 540 ? 22 : width <992 ?48 : 72,
                    textAlign: "end",
                    width: "100%",
                    lineHeight: 1,
                    zIndex: 2,
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  BE THE FIRST KONKOIN HOLDER NOW!
                </div>
              </div>
            </div>
          ) : (
            <div className="w-50" style={{ backgroundColor: "#d91f2d" }}>
              <div className="">
                <img
                  src={imgBA}
                  className="img-fluid"
                  alt=""
                  style={{ position: "absolute", height: "100%", left: "-8%" }}
                />
                <div
                  className=""
                  style={{
                    height: "100vh",
                    paddingRight: "10%",
                    paddingTop: "5%",
                    paddingBottom: "5%",
                  }}
                >
                  <div
                    className="d-flex align-items-end justify-content-between h-100"
                    style={{ flexDirection: "column" }}
                  >
                    <img
                      src={imgLogo}
                      className="img-fluid float-end"
                      alt=""
                      style={{ width: "35%" }}
                    />
                    <div
                      style={{
                        fontSize: 96,
                        textAlign: "end",
                        width: "60%",
                        lineHeight: 1,
                        zIndex: 2,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      BE THE FIRST KONKOIN HOLDER NOW!
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={width < rasioLayar ? "scrollbar-none" : "w-50 d-flex align-items-center scrollbar-none"}
            style={{ height: "100vh", overflow: "scroll" }}
          >
            <div
              className=""
              style={{
                width: "70%",
                marginLeft: "15%",
                marginTop: width < rasioLayar ? 50 : "200px",
              }}
            >
              <div
                className="text-dark fw-bold"
                style={{ fontSize: width < rasioLayar ? 22 : 56 }}
              >
                ISI DATA
              </div>
              <div
                className="d-flex justify-content-between"
                style={{ marginTop: width < rasioLayar ? 22 : 56 }}
              >
                <div className={width < rasioLayar ? "w-50 pe-3" : "w-50 pe-5"}>
                  <div
                    className={
                      width < rasioLayar
                        ? "fw-bold text-secondary font-12"
                        : "fw-bold text-secondary"
                    }
                  >
                    NAMA DEPAN
                  </div>
                  <div className="" style={{ borderBottom: "2px solid gray" }}>
                    <input
                      type="text"
                      value={firstName}
                      className={
                        width < rasioLayar
                          ? "form-control border-0 shadow-none mt-2"
                          : "form-control border-0 shadow-none mt-2"
                      }
                      name=""
                      onChange={(e) => {
                        setFirstName(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className={width < rasioLayar ? "w-50 pe-2" : "w-50 pe-5"}>
                  <div
                    className={
                      width < rasioLayar
                        ? "fw-bold text-secondary font-12"
                        : "fw-bold text-secondary"
                    }
                  >
                    NAMA BELAKANG
                  </div>
                  <div className="" style={{ borderBottom: "2px solid gray" }}>
                    <input
                      type="text"
                      className="form-control border-0 shadow-none mt-2"
                      name=""
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      id=""
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div className={width < rasioLayar ? "w-50 pe-2" : "w-50 pe-5"}>
                  <div
                    className={
                      width < rasioLayar
                        ? "fw-bold text-secondary font-12"
                        : "fw-bold text-secondary"
                    }
                  >
                    NOMOR TELEPON
                  </div>
                  <div className="" style={{ borderBottom: "2px solid gray" }}>
                    <input
                      type="number"
                      className="form-control border-0 shadow-none mt-2 remove-arrow1"
                      name=""
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      id=""
                    />
                  </div>
                </div>
                <div className={width < rasioLayar ? "w-50 pe-2" : "w-50 pe-5"}>
                  <div
                    className={
                      width < rasioLayar
                        ? "fw-bold text-secondary font-12"
                        : "fw-bold text-secondary"
                    }
                  >
                    ALAMAT EMAIL
                  </div>
                  <div className="" style={{ borderBottom: "2px solid gray" }}>
                    <input
                      type="text"
                      className="form-control border-0 shadow-none mt-2"
                      name=""
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      id=""
                    />
                  </div>
                </div>
              </div>
              <div
                className={width < rasioLayar ? "w-100 mt-4 pe-2" : "w-100 mt-4 pe-5"}
              >
                <div
                  className={
                    width < rasioLayar
                      ? "fw-bold text-secondary font-12"
                      : "fw-bold text-secondary"
                  }
                >
                  JUMLAH PESANAN
                </div>
                <div className="" style={{ borderBottom: "2px solid gray" }}>
                  <input
                    type="number"
                    className="form-control border-0 shadow-none mt-2 remove-arrow1"
                    name=""
                    value={total}
                    onChange={(e) => setTotal(e.target.value)}
                    id=""
                  />
                </div>
              </div>
              <>
                <div className="d-flex justify-content-between mt-4">
                  <div className={width < rasioLayar ? "w-50 pe-2" : "w-50 pe-5"}>
                    <div
                      className="btn border border-2 w-100 d-flex align-items-center justify-content-center"
                      style={{
                        borderRadius: 25,
                        height: width < rasioLayar ? 30 : 55,
                        backgroundColor: nominal === 1 ? "#d91f2d" : "",
                      }}
                      onClick={() => setNominal(1)}
                    >
                      <div
                        style={{ fontSize: width < rasioLayar ? 10 : 16 }}
                        className={
                          nominal === 1
                            ? "fw-bold text-white"
                            : "fw-bold text-secondary"
                        }
                      >{`< IDR 10 JUTA`}</div>
                    </div>
                  </div>
                  <div className={width < rasioLayar ? "w-50 pe-2" : "w-50 pe-5"}>
                    <div
                      className="btn border border-2 w-100 d-flex align-items-center justify-content-center"
                      style={{
                        borderRadius: 25,
                        height: width < rasioLayar ? 30 : 55,
                        backgroundColor: nominal === 2 ? "#d91f2d" : "",
                      }}
                      onClick={() => setNominal(2)}
                    >
                      <div
                        style={{ fontSize: width < rasioLayar ? 10 : 16 }}
                        className={
                          nominal === 2
                            ? "fw-bold text-white"
                            : "fw-bold text-secondary"
                        }
                      >{`IDR 10 - 100 JUTA`}</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div className={width < rasioLayar ? "w-50 pe-2" : "w-50 pe-5"}>
                    <div
                      className="btn border border-2 w-100 d-flex align-items-center justify-content-center"
                      style={{
                        borderRadius: 25,
                        height: width < rasioLayar ? 30 : 55,
                        backgroundColor: nominal === 3 ? "#d91f2d" : "",
                      }}
                      onClick={() => setNominal(3)}
                    >
                      <div
                        style={{ fontSize: width < rasioLayar ? 10 : 16 }}
                        className={
                          nominal === 3
                            ? "fw-bold text-white"
                            : "fw-bold text-secondary"
                        }
                      >{` IDR 100 - 500 JUTA`}</div>
                    </div>
                  </div>
                  <div className={width < rasioLayar ? "w-50 pe-2" : "w-50 pe-5"}>
                    <div
                      className="btn border border-2 w-100 d-flex align-items-center justify-content-center"
                      style={{
                        borderRadius: 25,
                        height: width < rasioLayar ? 30 : 55,
                        backgroundColor: nominal === 4 ? "#d91f2d" : "",
                      }}
                      onClick={() => setNominal(4)}
                    >
                      <div
                        style={{ fontSize: width < rasioLayar ? 10 : 16 }}
                        className={
                          nominal === 4
                            ? "fw-bold text-white"
                            : "fw-bold text-secondary"
                        }
                      >{`> IDR 500 JUTA`}</div>
                    </div>
                  </div>
                </div>
              </>
              <div className="d-flex">
                <div className={width < rasioLayar ? "w-100 mt-4" : "w-100 mt-4 pe-5"}>
                  <div className="fw-bold text-secondary">
                    UPLOAD ATTACHMENT
                  </div>
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    onChangeCapture={onFileChangeCapture}
                    style={{ display: "none" }}
                  />

                  <div
                    className="p-4 mt-4 d-flex justify-content-center"
                    style={{
                      border: "2px dashed rgb(10,10,10,0.5)",
                      borderRadius: 10,
                      cursor: "pointer",
                    }}
                    onClick={onButtonClick}
                  >
                    <img
                      src={evidence ? evidence : imgAttachment}
                      className="img-fluid"
                      style={{ width: "25%" }}
                      alt=""
                    />
                  </div>
                </div>
                {/* {evidence ? (
                  <div className="pt-4">
                    <img src={evidence} className="img-fluid" alt="" />
                  </div>
                ) : (
                  ""
                )} */}
              </div>
              <div className="w-100 mt-4 pe-5">
                <div className="fw-bold text-secondary">TATA CARA</div>

                <ul className="ps-3 mt-2" style={{ listStyleType : "decimal"}}>
                  <li>Silahkan transfer ke Rekening :</li>
                  <ul className="ps-4">
                    <li>
                      <strong>BCA 2915066699</strong>  an PT Cipta Utama Adhiguna
                      Nusantara{" "}
                      <span>
                        <Copy
                        className="ms-2"
                          onClick={() =>
                            navigator.clipboard.writeText("2915066699")
                          }
                        />
                      </span>
                    </li>
                    <li>
                      <strong>Mandiri 1640007789888</strong>   an PT Cipta Utama
                      Adhiguna Nusantara
                      <span>
                        <Copy
                         className="ms-2"s
                          onClick={() =>
                            navigator.clipboard.writeText("1640007789888")
                          }
                        />
                      </span>
                    </li>
                  </ul>
                  <li className="mt-2">Upload bukti transfer pada kolom diatas</li>
                </ul>
              </div>
              <div
                className={width < rasioLayar ? "w-100 pe-2" : "w-50 pe-5"}
                style={{ marginTop: 50 }}
              >
                {validateButton ? (
                  <div
                    className="btn w-100 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: 25,
                      height: width < rasioLayar ? 30 : 55,
                      fontSize: width < rasioLayar ? 10 : 16,
                      backgroundColor: "#d91f2d",
                    }}
                    onClick={() => handleSubmit()}
                  >
                    <div className="fw-bold text-white">SUBMIT NOW</div>
                  </div>
                ) : (
                  <div
                    className="btn w-100 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: 25,
                      height: width < rasioLayar ? 30 : 55,
                      fontSize: width < rasioLayar ? 10 : 16,
                      backgroundColor: "#000000",
                      marginBottom: width < rasioLayar ? 50 : "100px",
                    }}
                  >
                    <div className="fw-bold text-white">SUBMIT NOW</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
