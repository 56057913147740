/* eslint-disable*/
import React, { useEffect } from "react"
import { Route, Routes } from "react-router-dom"

import "./index.css"
import PrivateSale from "./page/private-sale"

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_API_BASE_URL == "http://localhost:3000") {
      console.log("localhost")
    }
  }, [])

  return (
    <div className="App text-inter bg-koni-dark" style={{ overflow: "hidden" }}>
      <Routes>
        <Route path="/private-sale/:reff" element={<PrivateSale />} />
        {/* </Route> */}
      </Routes>
    </div>
  )
}

export default App
